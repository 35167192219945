import { FillProperty } from 'csstype'
import React from 'react'
import { colors } from '../../../__styling/settings/colors'

export interface WesternSuburbsWeeklyLogoProps {
    className?: string
    width?: number
    height?: number
    fill?: FillProperty
    title?: string
}

const PerthNowWesternSuburbsLogo: React.FC<WesternSuburbsWeeklyLogoProps> = ({
    className,
    title,
    width = 130,
    height = 40,
    fill = colors.black,
}) => {
    return (
        <svg
            width={width}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 132 80"
            preserveAspectRatio="xMinYMid meet"
        >
            <title>{title || 'Perth Now - Western Suburbs'}</title>
            <path
                d="M5.83517 49.6012H12.7666L16.586 34.4429H16.6921L20.37 49.6012H27.2662L33.1367 25H27.1601L23.7297 40.5133H23.5882L19.6981 25H14.1812L10.2558 40.5133H10.1143L6.89611 25H0L5.83517 49.6012Z"
                fill="black"
            />
            <path
                d="M42.4423 49.9562C45.908 49.9562 48.2421 49.1042 49.6567 48.2522V44.3828C48.2067 44.9863 46.2617 45.5187 44.0337 45.5187C40.8509 45.5187 39.1887 44.7023 38.7997 41.7558H50.4347V40.1228C50.4347 33.9104 48.0653 30.3604 41.7704 30.3604C36.112 30.3604 32.4695 34.0169 32.4695 39.8743V40.3358C32.4695 46.5837 35.7584 49.9562 42.4423 49.9562ZM41.7704 34.4784C43.574 34.4784 44.4581 35.4724 44.4581 38.2413H38.7644C39.0473 35.2949 40.3558 34.4784 41.7704 34.4784Z"
                fill="black"
            />
            <path
                d="M58.4194 49.9562C64.007 49.9562 66.624 47.7907 66.624 43.6373C66.624 40.3713 65.2801 38.3478 61.4607 37.8153L59.7279 37.5668C58.2072 37.3538 57.5706 37.0699 57.5706 36.1114C57.5706 35.1174 58.4548 34.6559 60.2937 34.6559C62.5217 34.6559 64.2546 35.1174 65.457 35.5079V31.3544C64.007 30.7509 62.2034 30.3604 59.4803 30.3604C54.2464 30.3604 51.7708 32.6679 51.7708 36.4664C51.7708 40.1228 53.5744 41.8978 56.7926 42.3593L58.5255 42.6078C60.1169 42.8208 60.6827 43.1048 60.6827 44.0633C60.6827 45.0928 59.7632 45.6252 57.4999 45.6252C54.9536 45.6252 53.3622 45.0928 51.8769 44.5248V48.7137C53.3976 49.4592 55.8731 49.9562 58.4194 49.9562Z"
                fill="black"
            />
            <path
                d="M75.301 49.8852C77.2815 49.8852 78.6961 49.4947 79.4387 49.1042V45.0928C78.9436 45.1993 78.2363 45.3057 77.5997 45.3057C76.0791 45.3057 75.5132 44.8798 75.5132 43.5663V35.0464H79.3326V30.7154H75.5132V26.2425H69.289V30.7154H66.955V35.0464H69.289V44.4538C69.289 48.6072 71.517 49.8852 75.301 49.8852Z"
                fill="black"
            />
            <path
                d="M90.6419 49.9562C94.1076 49.9562 96.4417 49.1042 97.8562 48.2522V44.3828C96.4063 44.9863 94.4612 45.5187 92.2333 45.5187C89.0504 45.5187 87.3883 44.7023 86.9993 41.7558H98.6343V40.1228C98.6343 33.9104 96.2648 30.3604 89.9699 30.3604C84.3116 30.3604 80.669 34.0169 80.669 39.8743V40.3358C80.669 46.5837 83.9579 49.9562 90.6419 49.9562ZM89.9699 34.4784C91.7735 34.4784 92.6576 35.4724 92.6576 38.2413H86.9639C87.2468 35.2949 88.5553 34.4784 89.9699 34.4784Z"
                fill="black"
            />
            <path
                d="M100.611 49.6012H106.8V36.8214C107.861 36.2889 109.275 35.9694 110.938 35.9694C111.468 35.9694 112.14 36.0759 112.529 36.1469V30.6799C112.317 30.5734 111.928 30.5024 111.433 30.5024C109.452 30.5024 107.755 31.5319 106.765 32.9874H106.588V30.7154H100.611V49.6012Z"
                fill="black"
            />
            <path
                d="M114.318 49.6012H120.506V35.8629C121.285 35.5434 122.452 35.2239 123.442 35.2239C125.175 35.2239 125.811 35.7564 125.811 37.2473V49.6012H132V35.5434C132 31.8159 129.949 30.3604 126.448 30.3604C124.078 30.3604 122.098 31.0704 120.471 32.3839H120.294V30.7154H114.318V49.6012Z"
                fill="black"
            />
            <path
                d="M9.99314 79.0723C16.3588 79.0723 19.7184 76.1968 19.7184 70.9429C19.7184 65.9375 17.3136 64.198 12.9991 63.3815L11.1602 63.0265C9.00293 62.6005 8.29564 62.1035 8.29564 60.79C8.29564 59.37 9.42731 58.6955 11.6199 58.6955C14.4137 58.6955 16.5356 59.2635 18.2331 59.867V54.8971C16.8185 54.2226 14.8735 53.6191 10.9834 53.6191C4.75917 53.6191 1.85927 56.6721 1.85927 61.3225C1.85927 66.4699 4.26407 68.2094 8.15418 68.9549L9.99314 69.3099C12.3626 69.7714 12.9991 70.2329 12.9991 71.6529C12.9991 73.1439 11.9382 73.9604 9.17976 73.9604C6.35058 73.9604 4.08724 73.3569 1.93 72.5759V77.6168C3.69823 78.5398 6.56277 79.0723 9.99314 79.0723Z"
                fill="black"
            />
            <path
                d="M27.4235 79.0013C29.7929 79.0013 31.4551 78.2558 33.1172 76.9778H33.294L33.7184 78.6463H39.1646V59.7605H32.9758V73.5699C32.1977 73.9249 31.2429 74.2799 30.1466 74.2799C28.5198 74.2799 27.8832 73.7119 27.8832 72.1854V59.7605H21.6944V73.4989C21.6944 77.3683 23.8163 79.0013 27.4235 79.0013Z"
                fill="black"
            />
            <path
                d="M50.1691 79.0368C57.0298 79.0368 60.637 76.1258 60.637 68.8129V68.3869C60.637 61.5 57.2066 59.4055 53.6702 59.4055C51.3007 59.4055 49.6386 60.08 48.3301 61.145H48.1533V52.4121H41.9645V77.7233C44.0863 78.5043 46.9155 79.0368 50.1691 79.0368ZM50.5227 74.7413C49.4618 74.7413 48.6837 74.5283 48.1533 74.3153V64.8015C48.7191 64.5175 49.7447 64.1625 50.8056 64.1625C53.069 64.1625 54.2714 65.121 54.2714 69.0259V69.5939C54.2714 73.7474 52.5739 74.7413 50.5227 74.7413Z"
                fill="black"
            />
            <path
                d="M68.3401 79.0013C70.7096 79.0013 72.3717 78.2558 74.0339 76.9778H74.2107L74.6351 78.6463H80.0812V59.7605H73.8924V73.5699C73.1144 73.9249 72.1595 74.2799 71.0632 74.2799C69.4364 74.2799 68.7999 73.7119 68.7999 72.1854V59.7605H62.6111V73.4989C62.6111 77.3683 64.7329 79.0013 68.3401 79.0013Z"
                fill="black"
            />
            <path
                d="M82.8811 78.6463H89.0699V65.8665C90.1309 65.334 91.5454 65.0145 93.2076 65.0145C93.7381 65.0145 94.41 65.121 94.799 65.192V59.725C94.5868 59.6185 94.1978 59.5475 93.7027 59.5475C91.7223 59.5475 90.0248 60.577 89.0346 62.0325H88.8577V59.7605H82.8811V78.6463Z"
                fill="black"
            />
            <path
                d="M104.792 79.0368C111.653 79.0368 115.26 76.1258 115.26 68.8129V68.3869C115.26 61.5 111.83 59.4055 108.293 59.4055C105.924 59.4055 104.262 60.08 102.953 61.145H102.776V52.4121H96.5877V77.7233C98.7096 78.5043 101.539 79.0368 104.792 79.0368ZM105.146 74.7413C104.085 74.7413 103.307 74.5283 102.776 74.3153V64.8015C103.342 64.5175 104.368 64.1625 105.429 64.1625C107.692 64.1625 108.895 65.121 108.895 69.0259V69.5939C108.895 73.7474 107.197 74.7413 105.146 74.7413Z"
                fill="black"
            />
            <path
                d="M123.14 79.0013C128.728 79.0013 131.345 76.8358 131.345 72.6824C131.345 69.4164 130.001 67.3929 126.182 66.8604L124.449 66.6119C122.928 66.3989 122.291 66.1149 122.291 65.1565C122.291 64.1625 123.176 63.701 125.015 63.701C127.242 63.701 128.975 64.1625 130.178 64.553V60.3995C128.728 59.796 126.924 59.4055 124.201 59.4055C118.967 59.4055 116.492 61.713 116.492 65.5115C116.492 69.1679 118.295 70.9429 121.513 71.4044L123.246 71.6529C124.838 71.8659 125.404 72.1499 125.404 73.1084C125.404 74.1379 124.484 74.6703 122.221 74.6703C119.674 74.6703 118.083 74.1378 116.598 73.5699V77.7588C118.118 78.5043 120.594 79.0013 123.14 79.0013Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M67.3252 6.7494V16.1452C67.3252 19.8732 70.3918 22.8949 74.1714 22.8949H125.071C128.852 22.8949 131.917 19.8732 131.917 16.1452V6.7494C131.917 3.02272 128.852 0 125.071 0H74.1714C70.3918 0 67.3252 3.02272 67.3252 6.7494Z"
                fill="#D42626"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M103.734 4.37622H107.607L110.705 14.2447H110.764L113.654 4.37622H117.527L120.624 14.2447H120.683L123.662 4.37622H127.266L122.411 18.4746H118.866L115.47 8.84081H115.411L112.403 18.4746H108.709L103.734 4.37622"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M96.4497 15.694C99.071 15.694 100.65 13.6683 100.65 11.4652C100.65 9.26236 99.071 7.23568 96.4497 7.23568C93.8284 7.23568 92.2497 9.26236 92.2497 11.4652C92.2497 13.6683 93.8284 15.694 96.4497 15.694M96.45 4.06372C100.769 4.06372 104.225 7.03008 104.225 11.4652C104.225 15.8997 100.769 18.8673 96.45 18.8673C92.1309 18.8673 88.6758 15.8997 88.6758 11.4652C88.6758 7.03008 92.1309 4.06372 96.45 4.06372"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M74.0923 4.41634H77.4883V6.67778H77.548C78.2029 5.26826 79.5434 4.06372 81.9266 4.06372C85.7692 4.06372 87.1396 6.73671 87.1396 9.61467V18.5147H83.5653V11.3774C83.5653 9.82028 83.4461 7.23569 80.9437 7.23569C78.5907 7.23569 77.6669 8.93953 77.6669 10.8192V18.5147H74.0923V4.41634"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M64.5847 11.4468C64.5657 6.8661 62.7738 4.23139 58.3649 4.23139C56.9007 4.23139 55.6062 4.60982 54.3906 5.6124V0.283447H50.9512V18.5972H54.3906V10.8679C54.3906 9.22943 55.2608 7.53491 58.1298 7.53491C59.9598 7.53491 61.134 8.7636 61.134 10.3587C61.1495 10.7149 61.1524 11.0411 61.1524 11.3027V18.5972H64.5912V11.4629L64.5847 11.4468"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M49.0242 15.1994V15.1911L49.0097 15.1994C47.4429 15.1208 45.9324 13.9723 45.4228 12.7265C45.3076 12.4446 45.2417 11.9788 45.2417 11.4449V7.43773H49.0242V4.54177H45.2417V0.593506H41.8022V10.7454C41.8022 11.2216 41.8495 12.2245 41.94 12.6752C42.6058 16.0028 45.611 18.5133 49.2163 18.5133V15.2082C49.1519 15.2082 49.0886 15.2019 49.0242 15.1994"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.68 4.18715C39.5462 4.171 39.4113 4.15991 39.2716 4.15991L39.2066 4.16403C39.1778 4.16403 39.1497 4.15991 39.1203 4.15991C37.6563 4.15991 36.0534 4.50255 35.145 5.79141V4.50065H31.7065V18.5257H35.145V11.2844L35.1463 11.2353C35.1463 9.28651 36.3095 7.46375 39.1203 7.46375C39.6128 7.46375 40.0826 7.554 40.5189 7.71234V4.29291C40.2443 4.24161 39.9643 4.20931 39.68 4.18715"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.1898 7.46407C24.915 7.46407 26.3838 8.52556 26.9558 10.0136H19.4231C19.9958 8.52556 21.4633 7.46407 23.1898 7.46407M26.5277 13.5837C25.8049 14.6294 24.58 15.3178 23.1894 15.3178C21.3637 15.3178 19.8244 14.1325 19.3306 12.5096H27.047L27.046 12.5121H30.5417C30.5799 12.1362 30.6025 11.7724 30.6025 11.3914C30.6025 7.39725 27.2837 4.15991 23.1885 4.15991C19.0942 4.15991 15.7744 7.39725 15.7744 11.3914C15.7744 15.3856 19.0942 18.6226 23.1885 18.6226C25.6393 18.6226 27.8118 17.4636 29.1616 15.6766L26.5277 13.5837"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.43077 15.3175C5.20691 15.3175 3.40441 13.5597 3.40441 11.3915C3.40441 9.22225 5.20691 7.46346 7.43077 7.46346C9.21225 7.46346 10.7195 8.59334 11.2508 10.1561C11.353 10.5298 11.4064 10.9199 11.4064 11.3161C11.4064 12.1546 11.1684 12.9634 10.7286 13.6401C10.0003 14.6538 8.79607 15.3175 7.43077 15.3175M7.43022 4.15991C3.33595 4.15991 0.0164922 7.39693 0.0164922 11.3914C0.0164922 11.4259 0.0216662 11.4586 0.0216662 11.4921L0 11.5254V22.6339H3.43943V17.4779C4.59259 18.1992 5.9595 18.6223 7.43022 18.6223C11.5251 18.6223 14.8443 15.385 14.8443 11.3914C14.8443 7.39693 11.5251 4.15991 7.43022 4.15991"
                fill="black"
            />
        </svg>
    )
}

export default PerthNowWesternSuburbsLogo
